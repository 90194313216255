<template>
    <div>
        <b-card-group deck>
            <b-card
                :header="is_edit?'SỬA CHIẾN DỊCH ĐUA TOP':'TẠO CHIẾN DỊCH ĐUA TOP'"
                header-tag="header"
            >
                <b-form @submit="saveForm">
                    <h2 class="title-block">THÔNG TIN BẢNG XẾP HẠNG</h2>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Tên bảng xếp hạng (*)"
                                    label-for="ms-title"
                                    class="col-md-12"
                                >
                                    <b-form-input
                                        id="ms-title"
                                        v-model="form.name"
                                        maxlength="100"
                                        required
                                        placeholder="Tên bảng xếp hạng"
                                        :class="['col-md-12',{ 'is-invalid': form.errors.has('name') }]"
                                    ></b-form-input>
                                    <has-error :form="form" field="name"></has-error>
                                </b-form-group>
                            </div>
                            <div class="row">

                                <b-form-group
                                    id="input-group-4"
                                    label="Thời gian bắt đầu diễn ra (*)"
                                    label-for="dp1"
                                    class="col-md-6"
                                >
                                    <date-picker
                                        id="dp1"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        value-type="format"
                                        v-model="form.date_start"
                                        type="datetime"
                                        placeholder="Chọn thời gian"
                                        :input-attr="{required: 'true',name:''}"
                                    ></date-picker>
                                    <has-error :form="form" field="date_start"></has-error>
                                </b-form-group>


                                <b-form-group
                                    id="input-group-4"
                                    label="Thời gian kết thúc (*)"
                                    label-for="dp2"
                                    class="col-md-6"
                                >
                                    <date-picker
                                        id="dp2"
                                        v-model="form.date_end"
                                        type="datetime"
                                        placeholder="Chọn thời gian"
                                        :input-attr="{required: 'true',name:''}"
                                        format="YYYY-MM-DD HH:mm:ss"
                                        value-type="format"
                                        :disabled-date="disabledBefore"
                                    ></date-picker>
                                    <has-error :form="form" field="date_end"></has-error>
                                </b-form-group>
                            </div>

                            <div class="row">
                                <b-form-group
                                    id="input-type-gift"
                                    label="Segment user ( Bỏ trống để áp dụng cho all user)"
                                    label-for="gift-type"
                                    class="col-md-6"
                                >
                                    <b-form-select
                                        v-model="form.segment_id"
                                        lable=""
                                        :class="[{ 'is-invalid': form.errors.has('segment_id') }]"
                                        placeholder="-Chọn-"
                                    >
                                        <option value="">-chọn-</option>
                                        <option v-for="(item ,key) in list_segment" :value="item.id" :key="key">
                                            {{ item.title }}
                                        </option>


                                    </b-form-select>
                                    <has-error :form="form" field="provider_id"></has-error>
                                </b-form-group>
                                <b-form-group
                                    id="input-type-gift"
                                    label="Danh mục (*)"
                                    label-for="gift-type"
                                    class="col-md-6"
                                >
                                    <b-form-select
                                        v-model="category_selected"
                                        lable=""
                                        required
                                        :class="[{ 'is-invalid': form.errors.has('category') }]"
                                        placeholder="-Chọn-"
                                    >
                                        <option value="">-chọn-</option>
                                        <option v-for="(item ,key) in list_category" :value="item" :key="key">
                                            {{ item.name }}
                                        </option>


                                    </b-form-select>
                                    <has-error :form="form" field="category_id"></has-error>
                                </b-form-group>
                            </div>
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Số lượng hiển thị trên bảng xếp hạng"
                                    label-for="ms-title"
                                    class="col-md-6"
                                >
                                    <b-form-input
                                        id="ms-title"
                                        v-model="form.num_user_display"
                                        type="number"
                                        required
                                        placeholder="100"
                                        min="3"
                                        :class="['col-md-12',{ 'is-invalid': form.errors.has('num') }]"
                                    ></b-form-input>
                                    <has-error :form="form" field="name"></has-error>
                                </b-form-group>
                                <b-form-group
                                    id="input-type-gift"
                                    label="Dịch vụ thanh toán"
                                    label-for="gift-type"
                                    class="col-md-6"
                                >
                                    <multiselect
                                        v-model="service_code_selected"
                                        :options="service_code"
                                        :required="true"
                                        track-by="value"
                                        label="text"
                                        :multiple="true"
                                        placeholder="Chọn dịch vụ"
                                    ></multiselect>
                                    <has-error :form="form" field="service"></has-error>
                                </b-form-group>

                            </div>
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Tỷ lệ quy đổi điểm ( Ví dụ : 1 điểm = 1.000đ )"
                                    label-for="ms-title"
                                    class="col-md-6"
                                >
                                    <b-form-input
                                        id="ms-title"
                                        v-model="form.exchange_rate"
                                        type="number"
                                        required
                                        placeholder="100"
                                        min="3"
                                        :class="['col-md-12',{ 'is-invalid': form.errors.has('exchange_rate') }]"
                                    ></b-form-input>
                                    <has-error :form="form" field="name"></has-error>
                                </b-form-group>
                                <b-form-group
                                    id="input-type-gift"
                                    label="Nạp game"
                                    label-for="gift-type"
                                    class="col-md-6"
                                >
                                    <div class="form-inline">
                                        <b-form-radio :value="1" v-model="form.include_trans_game">Có</b-form-radio>
                                        <b-form-radio :value="0" style="margin-left: 30px"
                                                      v-model="form.include_trans_game">Không
                                        </b-form-radio>
                                    </div>
                                </b-form-group>

                            </div>
                            <div class="row">
                                <b-form-group
                                    id="input-type-gift"
                                    label="Bảng xếp hạng Game"
                                    label-for="gift-type1"
                                    class="col-md-6"
                                >
                                    <div class="form-inline">
                                        <b-form-radio :value="1" v-model="form.is_game">Có</b-form-radio>
                                        <b-form-radio :value="0" style="margin-left: 30px" v-model="form.is_game">
                                            Không
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                                <b-form-group
                                    id="input-type-gift"
                                    label="Hiển thị"
                                    label-for="gift-type"
                                    class="col-md-6"
                                >
                                    <div class="form-inline">
                                        <b-form-radio :value="1" v-model="form.status">Hiện</b-form-radio>
                                        <b-form-radio :value="0" style="margin-left: 30px" v-model="form.status">Ẩn
                                        </b-form-radio>
                                    </div>
                                </b-form-group>

                            </div>
                        </div>


                    </div>

                    <h2 class="title-block">CÀI ĐẶT THÔNG BÁO</h2>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Tiêu đề thông báo"
                                    label-for="ms-title"
                                    class="col-md-12"
                                >
                                    <b-form-input
                                        id="ms-title"
                                        v-model="form.noti_title"
                                        maxlength="200"
                                        placeholder="Tiêu đề thông báo"
                                        :class="['col-md-12',{ 'is-invalid': form.errors.has('noti_title') }]"
                                    ></b-form-input>
                                    <has-error :form="form" field="noti_title"></has-error>
                                </b-form-group>
                            </div>
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Mô tả thông báo"
                                    label-for="ms-title"
                                    class="col-md-12"
                                >
                                    <b-form-textarea
                                        id="ms-title"
                                        v-model="form.noti_desc"
                                        maxlength="200"
                                        placeholder="Mô tả thông báo"
                                        :class="['col-md-12',{ 'is-invalid': form.errors.has('noti_desc') }]"
                                    ></b-form-textarea>
                                    <has-error :form="form" field="noti_desc"></has-error>
                                </b-form-group>
                            </div>
                            <div class="row">
                                <b-form-group
                                    id="input-group-0"
                                    label="Icon thông bác:"
                                    label-for="input-0"
                                    class="col-md-12"
                                >
                                    <div style="display: flex">
                                        <div style="height: 38px; margin-right: 20px"
                                             v-if="form.noti_icon && form.noti_icon.length >0">
                                            <img
                                                style="width: 38px; height: 38px"
                                                :src="form.noti_icon"
                                                alt=""
                                            />
                                        </div>
                                        <b-form-file
                                            @change="onImageChange($event)"
                                            placeholder="chọn icon"
                                            type="file"
                                            accept="image/*"

                                        ></b-form-file>
                                    </div>

                                </b-form-group>
                            </div>

                        </div>


                    </div>

                    <h2 class="title-block">CÀI ĐẶT BUTTON CTA</h2>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Tên button (*)"
                                    label-for="ms-title"
                                    class="col-md-12"
                                >
                                    <b-form-input
                                        id="ms-title"
                                        v-model="form.text_button"
                                        maxlength="200"
                                        required
                                        placeholder="Tên button"
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="row">
                                <b-form-group
                                    id="input-dk-2"
                                    label="Điều hướng (*)"
                                    label-for="ms-title"
                                    class="col-md-12"
                                >
                                    <multiselect
                                        v-model="form.button_route"
                                        :options="options.directions"
                                        :multiple="false"
                                        label="text"
                                        track-by="value"
                                    ></multiselect>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="">
                        <b-button type="submit" variant="primary">{{
                                is_edit ? "Cập nhập" : "Xác nhận"
                            }}
                        </b-button>
                        <router-link v-if="is_edit" :to="'/top-service'">
                            <b-button variant="danger">Hủy bỏ</b-button>
                        </router-link>
                        <b-button v-else type="reset" variant="danger">Làm mới</b-button>
                    </div>
                </b-form>
            </b-card>
        </b-card-group>

    </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from "vue-multiselect";
import Vue from "vue";
import {Form} from 'vform';
import {
    HasError,
} from 'vform/src/components/bootstrap5'
import {VueEditor} from "vue2-editor";

Vue.component("multiselect", Multiselect);
Vue.component(HasError.name, HasError)
const WalletRunRepository = RepositoryFactory.get("WalletRun");
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import CmsRepository from "@/core/repositories/cmsRepository";
import CampaignRepository from "@/core/repositories/CampaignRepository";
import EventRepository from "@/core/repositories/EventRepository";

export default {
    name: "FormMission",
    mixins: [Common],
    components: {
        // eslint-disable-next-line vue/no-unused-components
        VueEditor,
        DatePicker
    },
    data() {
        return {
            form: new Form({
                id: '',
                name: '',
                segment_id: '',
                category_id: '',
                category: '',
                num_user_display: '',
                service: [],
                date_start: '',
                date_end: '',
                status: 1,
                is_game: 0,
                exchange_rate: 1000,
                noti_icon: '',
                noti_title: '',
                noti_desc: '',
                text_button: '',
                button_route: '',
                include_trans_game: 0,
            }),

            is_edit: false,
            list_category: [],
            category_selected: [],
            list_segment: [],
            service_code: [],
            service_code_selected: [],
            options: {
                directions: [
                    {value: 1, text: "Ẩn"},
                ],
            },
        };

    },
    computed: {},
    watch: {},
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            {title: "Chiến dịch đua top ", route: "/top-service/create"},
            {title: "Thêm chiến dịch"},
        ]);

    },
    methods: {
        disabledBefore(date) {
            return date < Date.parse(this.form.date_start);
        },

        saveForm() {
            let raw_service = [];
            this.service_code_selected.forEach((item) => {
                raw_service.push(item.value)
            })
            if(typeof this.form.button_route == 'undefined' || this.form.button_route == ''){
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Vui lòng chọn điều hướng button.",
                });
                return;
            }
            this.form.button_cta = JSON.stringify({
                text_button:this.form.text_button,
                button_route:this.form.button_route.value
            });
            this.form.service = JSON.stringify(raw_service)
            this.form.category_id = this.category_selected.id
            this.form.category = JSON.stringify(this.category_selected)
            let url = '/top-service'
            if (this.is_edit) {
                url = '/top-service/' + this.form.id;

            }
            this.form.submit('post', url)
                .then(({data}) => {
                    if (1 === data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: data.message,
                        });
                    } else {
                        this.$notify({
                            group: "foo",
                            type: "success",
                            title: data.message,
                        });
                        this.$router.push({name: "top-service"});
                    }
                })
                .catch(() => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Vui lòng kiểm tra lại form nhập liệu.",
                    });
                })

        },
        async loadCampaign(id) {
            try {
                let response = await EventRepository.show(id);
                if (response.data.error_code === 0) {
                    let data = response.data.data;

                    this.form.fill(data);
                    this.form.category = JSON.parse(data.category);
                    this.category_selected = JSON.parse(data.category);
                    this.form.service = JSON.parse(data.service);
                    let button_cta = JSON.parse(data.button_cta);
                    let code_selected = JSON.parse(data.service);

                    setTimeout(() => {
                        code_selected.forEach((item) => {
                            this.service_code.forEach((option) => {
                                if (item == option.value) {
                                    this.service_code_selected.push(option);
                                }
                            })
                        });
                        if(button_cta){
                            this.form.text_button = button_cta.text_button;
                            this.form.button_route = this.options.directions.find(option => option.value == button_cta.button_route);
                        }
                    }, 1000)
                } else {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                }
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: error,
                });
            }
        },
        async loadSegment() {
            try {
                let param = {'per_page': 1000}
                let response = await CmsRepository.listAllSegment(param);
                if (response.data.error_code === 0) {
                    let data = response.data.data;
                    this.list_segment = data.data;

                } else {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                }
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: error,
                });
            }
        },
        async loadCategory() {
            try {
                let response = await CampaignRepository.getCategoryList();
                if (response.data.error_code === 0) {
                    let data = response.data.data;
                    this.list_category = data;

                } else {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                }
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: error,
                });
            }
        },
        async getCodes() {
            let that = this;
            this.$bus.$emit("show-loading", true);
            CmsRepository.listService()
                .then((response) => {
                    this.$bus.$emit("show-loading", false);
                    if (response.data.error_code) {
                        alert(response.data.message);
                    } else {
                        that.service_code = response.data.data;
                    }
                })
                .catch(() => {
                    this.$bus.$emit("show-loading", false);
                    alert("Có lỗi xảy ra");
                });

        },
        getDirections() {
            let directions = [];
            this.options.directions = [];
            let params = {
                skip_paginate:1
            };
            CmsRepository.listRouteApp(params)
                .then((response) => {
                    if (response.data.error_code) {
                        this.notifyAlert("warn", response.message);
                        return;
                    }
                    response = response.data.data;
                    response.map(function(val){
                        directions.push({
                            value:val.id,
                            text:val.name
                        });
                    })
                    this.options.directions = [...directions];
                    return;
                })
                .catch(() => {
                    alert("Có lỗi xảy ra");
                });
        },
        onReset(evt) {
            evt.preventDefault();
            this.form = {
                name: '',
                provider_id: '',
                image: '',
                description: '',
                status: 1,
                active: 1,
                date_start: '',
                date_end: '',
                is_allowed_pass: '',
                position: '',
                target_money: '',
                suggest_money: '',
                suggest_content: '',
            };
            this.suggest_content_array = ['']
            this.suggest_money_array = ['']

        },
        onImageChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let fileSize = files[0].size / 1024 / 1024; // in MiB
            if (fileSize > 2) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Chọn ảnh không vượt quá 2MB",
                });
                return false;
            }
            this.images = files[0];
            this.uploadMedia();
            //this.createImage(files[0]);
        },
        uploadMedia: async function () {
            this.$bus.$emit("show-loading", true);
            const formData = new FormData();
            formData.append("file", this.images);

            WalletRunRepository.uploadMedia(formData)
                .then((response) => {
                    this.$bus.$emit("show-loading", false);
                    if (1 === response.data.error_code) {
                        this.$notify({
                            group: "foo",
                            type: "error",
                            title: response.data.message,
                        });
                    } else {
                        this.form.noti_icon = response.data.data.link;
                    }
                })
                .catch((response) => {
                    this.$bus.$emit("show-loading", false);
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: response.data.message,
                    });
                });
        },
    },
    async created() {
        this.loadSegment();
        this.loadCategory();
        this.getDirections();
        try {
            await this.getCodes(); // Chờ this.getCodes() hoàn thành
            if (this.$route.params.id) {
                this.is_edit = true;
                this.loadCampaign(this.$route.params.id);
            }
        } catch (error) {
            // Xử lý lỗi nếu cần
        }

    },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.title-block {
    color: #FF0000;
    font-size: 18px;
    font-weight: bold;
}

.datetime-picker div input {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.3rem + 2px);
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #E4E6EF;
    border-radius: 0.42rem;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.mx-datepicker {
    width: 100% !important;
}

.mx-datepicker input {
    height: 39px !important;
}
</style>